body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2c3e50;
  height: 100vh;
  position: relative;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.container-intro {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  height: 130px;
  width: 250px;
}

.hello-intro > h2 {
  font-size: 2em;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  justify-content: center;
}

.about,
.portfolio {
  text-decoration: none;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 405;
  color: #474747;
  text-align: center;
  margin: 6px;
  cursor: pointer;
}

.pipe {
  font-size: 16px;
  color: rgb(0, 0, 0);
  margin: 6px;
}
img {
  height: auto;
  width: 100%;
}
#particles-js {
  height: 100%;
  background: #6eb5ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.particles-js-canvas-el {
  position: absolute;
}

.container-intro a:hover {
  color: #efefef;
  transition-delay: 0.1s;
  text-decoration: none;
  font-weight: none;
}

.scroll_down {
  text-align: center;
  width: 100px;
  margin-left: -50px;
  position: absolute;
  bottom: 70px;
  left: 50%;
  fill: #686868;
  cursor: pointer;
}

.scroll_down:hover {
  transform: scale(1.3);
}
/* fondo animado */
.p-wallpaper {
  width: 100%;
}
.p-wallpaper::before {
  -webkit-animation: bganime 15s linear infinite;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 0.1;
  z-index: -1;
  animation: bganime 15s linear infinite;
  background: url(../public/assets/imgs/bg_pattern.png) center repeat
    transparent;

  width: 100%;
  height: 100%;
  content: "";
}

/* fin del fondo animado*/
h1 {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 2.3em;
  margin-bottom: 0.5em;
  text-align: center;
  margin-top: 45px;
}

/* Main*/
.main-container {
  margin-bottom: 100px;
}
/* Project container*/
.projects-container {
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
}
.mt-40 {
  margin-top: 40;
}
.title-center h2 {
  text-align: center;
  color: #cacaca;
  padding-bottom: 25px;
  padding-top: 15px;
}
.title-center h2 {
  margin: 0;
}
.phrase{
  text-align: center;
}
.phrase h2 {
  color: #cacaca;
  font-family: "Josefin Slab", serif;
  width: 95%;
  margin: 0 auto;
}

.project-title {
  color: #cacaca;
  font-size: 2.625em;
  line-height: 1.45em;
  font-weight: 700;
  letter-spacing: -2px;
  margin-bottom: 0.3em;
  text-transform: lowercase;
  width: 100%;
  text-shadow: 2px 2px 0 rgb(0 0 0 / 15%);
}
.project-desc {
  margin-top: 5px;
  color: #e6e6e6;
}
.redes {
  font-size: 50px;
  padding-right: 15px;
}

p {
  font-family: "Lora", serif;

  color: #000000c9;
  text-align: center;
  line-height: 1.8;
  font-size: 1.5em;
  display: block;
  width: 60%;
  font-weight: 500;
}

h3 {
  font-family: "Lora", serif;
  color: #858585;
  text-align: center;
  display: block;
  line-height: 1.3;
  margin: 0 auto;
  width: 60%;
  font-weight: 500;
  font-size: 1.4em;
  margin-bottom: 1px;
}

ol {
  font-family: "Lora", serif;
  color: #858585;
  line-height: 1.8;
  font-size: 1.2em;
  list-style: none;
  margin-bottom: 30px;
  list-style-position: inside;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  justify-content: center;
}

ol li a img {
  text-decoration: none;
  color: #2c3e50;
  font-family: "Lora", serif;
  width: 40px;
}

.about_me > a {
  text-decoration: none;
  font-family: "Lora", serif;
  background-color: transparent;
}

a {
  color: #6eb5ff;
}

.about_me a:hover {
  transition-delay: 0.3s;
  color: #ffffff;
}

/* About Section */
.about-section {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}
.about--right{
  display: flex;
  justify-content: flex-end;
  width: 90%;

}
.about_me .about--cv{
  text-decoration: underline;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 1.3rem;
}
.avatar {
  display: inline-block;
  overflow: hidden;
  line-height: 1;
  flex-shrink: 0;
  filter: grayscale(50%);
}
.about-section img {
  margin-top: 80px;
  width: 200px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.8);
  border-radius: 50%;
}
.questions {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  letter-spacing: 0.02em;
}
.questions a {
  text-decoration: none;
  color: #fff;
  transition: all 0.25s ease-in-out 0s;
  display: inline-block;
}
.questions a::after {
  content: "";
  display: block;
  width: 0px;
  height: 1px;
  background: #fff;
  opacity: 0.7;
  transition: width 0.3s ease 0s;
}
.questions a:hover {
  /* color: #ffabab; */
  color: #fff;
  opacity: 0.7;
}
.questions a:hover::after {
  width: 100%;
}
.about-item {
  max-width: 500px;
  width: 95%;
  height: auto;
}
.about-item:first-child {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/portfolio-react-5f0f7.appspot.com/o/han.jpg?alt=media&token=e5c1f45a-2d36-413c-b178-1f79dd81ca90);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #6eb4ff75;
  opacity: 1;
  box-shadow: inset 0 0 12px 0 #b6b2b2;
  border-radius: 15px;
}
.about_me {
  width: 100%;
  color: #cacaca;
  text-align: start;
  padding: 1em 1.5em 0 1.5em;
  margin: 0;
}


/* Projects Container */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
}

.card-item {
  width: 415px;
  padding: 0.5rem;
}

.card-item img {
  width: 100%;
  height: 230px;
}
.card-title-screen {
  display: block;
  position: relative;
  height: 24px;
  background-color: #ddd;
  width: 100%;
  color: #6a6a6a;
  z-index: 10;
}
.card-title-screen h2 {
  content: attr(data-text);
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  font-family: MontSerrat, Tahoma, Arial, sans-serif;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.5px;
}

.card-title-screen i,
.card-title-screen i::after,
.card-title-screen i:before,
article .card-title-screen i,
article .card-title-screen i:after,
article .card-title-screen i:before {
  height: 12px;
  width: 12px;
  display: inline-block;
  background-color: #ff5f57;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 10px;
}
.card-title-screen i:before,
article .card-title-screen i:before {
  content: " ";
  left: 16px;
  background-color: #ffbd2e;
  top: 0;
}

.card-title-screen i:after,
article .card-title-screen i:after {
  content: " ";
  left: 32px;
  background-color: #28ca41;
  top: 0;
}

.main {
  background-size: cover;
  padding-top: 58%;
  position: relative;
  background-position: center center;
  display: block;
  width: 100%;
  border-radius: 0;
  -moz-transform-style: flat;
  -webkit-transform-style: flat;
  transform-style: flat;
}
.main:before {
  content: "";
  position: absolute;
  z-index: 20;
  box-shadow: 0 0 50px rgb(0 0 0 / 75%) inset;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(255, 255, 255, 0.4);
}
.card-container .main-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* * ProJects Card effect*/
.image {
  overflow: hidden;
}
.image--close {
  position: relative;
}

.image--close::before,
.image--close::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(23, 23, 23, 0.8);
  transition: transform 0.4s;
  z-index: 50;
}

.image--close::before {
  top: 0;
  right: 0;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  transform: translateX(100%);
}
.image--close::after {
  top: 0;
  left: 0;
  clip-path: polygon(0 0, 0 100%, 100% 100%);
  transform: translateX(-100%);
}

.image--close:hover::before {
  transform: translateX(10%);
}
.image--close:hover::after {
  transform: translateX(-10%);
}

.image__title--close {
  background-color: rgba(57, 98, 136, 0.7);
  color: #fff;
  width: 35%;
  text-align: center;
  position: absolute;
  line-height: 2;
  left: 50%;
  top: 40%;
  transform: translateX(-50%) scale(0);
  z-index: 60;
  transition: transform 0.4s;
}

.image--close:hover .image__title--close {
  transform: translateX(-50%) scale(1);
}
.read-more {
  cursor: pointer;
}
/* *Modal */
.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  justify-content: center;
  align-items: center;
}
.modal.is-open {
  display: flex;
}

.modal-container {
  position: relative;
  padding: 1rem;
  min-width: 320px;
  max-width: 900px;
  min-height: 200px;
  max-height: 100vh;
  overflow-y: scroll;
  background-color: rgba(57, 98, 136, 0.7);
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #d2d2d2;
  font-size: 45px;
  cursor: pointer;
}

/* Info Page*/

.card-container-info {
  max-width: 800px;
  margin: 0 auto;
}
.text {
  text-align: left;
  color: #cacaca;
  display: block;
  font-family: Lato, Helvetica, Arial, sans-serif;
}

.text h2 {
  font-size: 2em;
  line-height: 1.35em;
  font-weight: 600;
  position: relative;
  display: block;
  letter-spacing: -0.045em;
  padding: 0;
  margin-bottom: 0.4em;
  font-family: MontSerrat, Tahoma, Arial, sans-serif;
}
.text hr {
  display: block;
  height: 1px;
  width: 50%;
  background-color: #484848;
  position: relative;
  border: 0;
  margin: 0.5em 0 1em;
}
.text p {
  margin-bottom: 1em;
  text-align: left;
  color: #cacaca;
  width: 100%;
}
.text .section ul {
  display: block;
  list-style: circle;
  padding: 0 1.4em;
  margin-bottom: 0.6em;
}
.text li {
  margin-bottom: 3px;
  font-size: 1.3em;
  line-height: 1.65em;
  color: #cacaca;
}

.text > div {
  margin-bottom: 3em;
}
/* Section Skills */
.section-c {
  padding: 25px 0;
  padding-bottom: 60px;
}

.container-section {
  overflow: hidden;
  width: 90%;
  margin: auto;
}
.skills-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.skill {
  width: 100px;
  padding: 0px 15px;
}
.thumb {
  margin-bottom: 10px;
}
.thumb img {
  vertical-align: top;
  border-radius: 5px;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  -ms-transition: -ms-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
}
.thumb img:hover {
  -ms-transform: rotate(20deg);
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

footer {
  width: 100%;
}
.footer-line {
  border-top: black solid 3px;
  box-shadow: 0 1px 0 0 #858585;
  background-color: inherit;
  color: #858585;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 1em;
}
.ja {
  padding-bottom: 15px;
  text-align: center;
  font-size: 1em;
  margin-top: 30px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.projects-links {
  text-decoration: none;
  font-family: "Lora", serif;
  background-color: transparent;
}

.projects-p a:hover {
  background-color: #f8f8f8;
}

/*vvvvvvvvvvvvvvv*/
.p-wallpaper {
  position: relative;
}

@-webkit-keyframes bganime {
  0% {
    background-position: 0 -0;
  }
  100% {
    background-position: -0 -1000px;
  }
}
@keyframes bganime {
  0% {
    background-position: 0 -0;
  }
  100% {
    background-position: -0 -1000px;
  }
}
@media screen and (max-width: 420px) {
  .about_me {
    padding: 1em;
    font-size:larger;
  }
  .about-section img {
    width: 200px;
    margin-top: 10px;
  }

  .card-item {
    width: 410px;
  }
  h3 {
    font-size: 1.2em;
  }
  .redes {
    font-size: 35px;
  }
  .ja {
    font-size: small;
  }
  /* Modal */
  .project-title {
    font-size: 1.5em;
  }
  .text h2 {
    font-size: 1em;
    letter-spacing: -0.02em;
  }
  .text p {
    margin-bottom: 0.5em;
    font-size: 1em;
  }
  .text li {
    font-size: 0.9em;
  }
}
.abt {
  margin-top: 0;
}
@media screen and (max-width: 400px) {
  .card-item {
    width: 370px;
  }
}
@media screen and (max-width: 380px) {
  .card-item {
    width: 375px;
  }
}
@media screen and (max-width: 360px) {
  .card-item {
    width: 355px;
  }
}
